<template>
  <div>
    <component :is="baseComponent" v-bind="baseComponentProps"></component>
    <div class="card mt-5">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="comments" /> Mattermost
      </p>
    </header>
     <div class="card-content">
       <b-loading v-model="loading" :is-full-page="false" />

       <div class="content">
         <table class="table is-fullwidth is-bordered" v-if="!!Object.keys(data).length">
           <tbody>
             <tr>
               <td><b-icon class="pr-3" icon="globe"/> URL</td> <td> <a :href="data.mattermost_url">{{ data.mattermost_url }}</a> </td>
             </tr>
             <tr>
               <td><b-icon class="pr-3" icon="user"/> Username</td> <td> <code>{{ data.mattermost_username }}</code> </td>
             </tr>
             <tr>
               <td><b-icon class="pr-3" icon="lock"/> Initial Password </td> <td> <code>{{ data.initial_pwd }}</code> </td>
             </tr>
           </tbody>
         </table>
         <div class="has-text-centered" v-else-if="!loading">
           <p>No mattermost user found!</p>
           <p><b-button icon-left="sign-in-alt" class="is-link" @click="register">Join Mattermost</b-button></p>
         </div>
       </div>
     </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    baseComponent: { required: true },
    baseComponentProps: { required: true },
  },
  data() {
    return {
      data: {},
      loading: true,
    };
  },
  methods: {
    async refresh() {
      try {
        this.data = await this.$ctforge.api.get('/mattermost/get-data');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      } finally {
        this.loading = false;
      }
    },
    async register() {
      this.loading = true;
      try {
        this.data = await this.$ctforge.api.post('/mattermost/register');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      } finally {
        this.loading = false;
      }
      await this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
<style>
</style>
