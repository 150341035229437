<template>
<Login>
  <template v-slot:buttons>
    <div class="buttons mt-5" v-if="!isLoggedin">
      <a href="/saml/login" class="button is-link">
        <span class="icon">
          <i class="fa fa-sign-in-alt"></i>
        </span>
        <span>TU Wien Login</span>
      </a>
    </div>
  </template>
</Login>
</template>

<script>
import Login from '../../../src/components/Login.vue';

export default {
  components: {
    Login,
  },
};
</script>

<style>
</style>
