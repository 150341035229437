import FirstLogin from './components/FirstLogin.vue';
import Login from './components/Login.vue';

export default {

  install(vue) {
    vue.$router.addRoutes([
      { path: '/first', component: FirstLogin },
      { path: '/login', component: Login },
    ]);
  },

};
