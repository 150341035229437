<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="user-lock" /> Roles
      </p>
    </header>

    <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

    <div class="card-content" v-else>
      <div class="content">

        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ roles.length }}</strong> Roles
              </p>

            </div>
          </div>
          <div class="level-right">
            <p class="level-item">
              <b-button icon-left="plus" tag="router-link"
                        to="/admin/roles/add">
                Add Role
              </b-button>
            </p>
          </div>
        </nav>

        <b-table
          :data="roles"
          hoverable
          default-sort="id">
          <b-table-column field="id" label="#" numeric centered width='40' sortable v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="name" label="Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="permissions" label="Permissions" sortable v-slot="props">
            <span class="tag is-danger ml-2" v-if="props.row.name == 'Admin'">
              Admin
            </span>
            <span class="tag is-primary ml-2" v-for="prm in props.row.permissions" :key="prm" v-else>
              {{ prm }}
            </span>
          </b-table-column>
          <b-table-column label="Actions" width="100" centered v-slot="props">
            <router-link :to="'/admin/roles/edit/' + props.row.id">
              <b-icon class="pr-3" icon="edit"/>
            </router-link>
            <a v-on:click="deleteRole(props.row.id)">
              <b-icon class="pl-3" icon="trash" />
            </a>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      roles: [],
      unauthorized: false,
    };
  },
  methods: {
    deleteRole(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Role',
        message: `Are you sure you want to <b>delete</b> role ${id}? This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$ctforge.api.post('/admin/acs/roles/delete', {
              id,
            });
            await this.loadRoles();
            this.notify('Role Deleted', 'is-info');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    async loadRoles() {
      try {
        this.roles = await this.$ctforge.api.get('/admin/acs/roles/list');
      } catch (e) {
        if (e.status_code === 401) {
          this.unauthorized = true;
        } else this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    await this.loadRoles();
  },
};
</script>

<style>
</style>
