<template>
  <div>
    <div class="card mt-5">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="comments" /> Mattermost
        </p>
      </header>
      <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>
      <div class="card-content" v-else>
        <div class="content">
          <form @submit.prevent="update" class="">
              <b-field horizontal label="Matermost Server">
                <b-input name="hostname" placeholder="Hostname (e.g., mattermost.example.com)"
                         v-model="data.url" expanded></b-input>
              </b-field>

              <b-field horizontal>
                <b-input name="scheme" v-model="data.scheme" placeholder="Scheme (default: https)" expanded></b-input>
                <b-input name="port" v-model="data.port" placeholder="Port (default: 443)" expanded></b-input>
              </b-field>

              <b-field horizontal label="Login ID">
                <b-input name="username" placeholder="username"
                         v-model="data.login_id" expanded></b-input>
              </b-field>
              <b-field horizontal label="Access Token">
                <b-input name="token" placeholder="token"
                         v-model="data.token" expanded></b-input>
              </b-field>
              <b-field horizontal label="Team Name">
                <b-input name="team" placeholder="team"
                         v-model="data.team_name" expanded></b-input>
              </b-field>

              <b-field>
                <p class="control pt-5">
                  <b-button label="Update" type="is-primary" expanded native-type="submit" />
                </p>
              </b-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      unauthorized: false,
    };
  },
  methods: {
    async refresh() {
      try {
        this.data = await this.$ctforge.api.get('/mattermost/admin/get-config');
      } catch (e) {
        if (e.status_code === 401) {
          this.unauthorized = true;
        } else this.notify(e.message, 'is-danger');
      }
    },
    async update() {
      if (!this.data) return;
      try {
        await this.$ctforge.api.post('/mattermost/admin/set-config', this.data);
        this.notify('Updated.', 'is-info');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
<style>
</style>
