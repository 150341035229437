<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <span v-if="isEditing">
          <b-icon icon="edit" />
          Edit Challenge
        </span>
        <span v-else>
          <b-icon icon="plus" />
          Add Challenge
        </span>
      </p>
      <span class="card-header-icon" aria-label="back">
        <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
      </span>

    </header>
    <div class="card-content">
      <div class="content">
        <form @submit.prevent="submit">
          <b-field label="Challenge name">
            <b-input type="text" required v-model="challenge.name"></b-input>
          </b-field>

          <b-tabs position="is-centered" >
            <b-tab-item label="Edit" icon="edit">
              <b-field>
                <b-switch style="float:right;" v-model="challenge.markdown" class="mt-1 ">Markdown</b-switch>
              </b-field>
              <b-field label="Description" class="pt-1">
                <MonacoEditor class="editor" style="height: 400px" v-model="challenge.description" :language="challenge.markdown? 'markdown':'html'" theme="vs-dark" :options="monaco_options" />
              </b-field>
            </b-tab-item>
            <b-tab-item label="Preview" icon="eye">
              <div class="content" v-html="challenge.description" v-if="!challenge.markdown"></div>
              <div class="content" v-else v-html="markdowPreview"></div>
            </b-tab-item>
          </b-tabs>

          <b-field label="Flag">
            <b-input type="text" required v-model="challenge.flag"></b-input>
          </b-field>

          <b-field label="Points">
            <b-input type="number" required v-model="challenge.points"></b-input>
          </b-field>

          <b-field label="Tags">
            <b-input type="text" required v-model="challenge.tags"></b-input>
          </b-field>

          <b-field label="Submission Start Time">
            <b-datetimepicker
              v-model="challenge.submission_starting_time"
              placeholder="Click to select..."
              icon="calendar"
              horizontal-time-picker required expanded />
          </b-field>

          <b-field label="Submission Ending Time">
            <b-datetimepicker
              v-model="challenge.submission_ending_time"
              placeholder="Click to select..."
              icon="calendar"
              horizontal-time-picker required expanded />
          </b-field>

          <b-field>
            <b-checkbox v-model="challenge.active">Active</b-checkbox>
          </b-field>

          <b-field label="Scoring Parameter">
            <b-select placeholder="Please select one" v-model="challenge.scoring_parameter">
              <option value="static">static</option>
              <option value="dynamic">dynamic</option>
              <option value="nsolver_dependent">solver-dependent</option>
            </b-select>
          </b-field>

          <b-field v-if="isDynamic" label="Scoring Dynamic Minimum">
            <b-input type="number" required v-model="challenge.scoring_dynamic_minimum"></b-input>
          </b-field>

          <b-field v-if="isDynamic" label="Scoring Dynamic Decay">
            <b-input type="number" required v-model="challenge.scoring_dynamic_decay"></b-input>
          </b-field>

          <slot name="extras" v-bind:challenge="challenge"></slot>

          <b-field grouped class="pt-3">
            <b-button native-type="submit" class="is-primary">Save</b-button>
            <b-button class="ml-3" @click="cancel">Cancel</b-button>
          </b-field>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MonacoEditor from 'vue-monaco';
import marked from 'marked';

export default {
  props: {
    isEditing: Boolean,
    challengeName: {
      type: String,
      default: null,
      required: false,
    },
  },
  components: {
    MonacoEditor,
  },
  data() {
    return {
      challengeId: null,
      challenge: {
        name: '',
        description: '',
        markdown: false,
        flag: '',
        points: 0,
        tags: '',
        submission_starting_time: null,
        submission_ending_time: null,
        active: true,
        scoring_parameter: null,
        scoring_dynamic_minimum: 1,
        scoring_dynamic_decay: 30,
      },
      monaco_options: {
        tabSize: 4,
        lineNumbers: true,
        lineNumbersMinChars: 3,
        folding: false,
        minimap: { enabled: true },
        automaticLayout: true,
        wordWrap: true,
      },
    };
  },
  methods: {
    async submit() {
      // browser takes care of validation using required fields (works because we're using onSubmit)
      try {
        if (!this.isDynamic) {
          this.challenge.scoring_dynamic_minimum = 0;
          this.challenge.scoring_dynamic_decay = 0;
        }

        if (this.isEditing) {
          await this.$ctforge.api.post('/admin/challenges/edit', {
            challenge_id: this.challengeId,
            ...this.challenge,
          });
        } else {
          const resp = await this.$ctforge.api.post('/admin/challenges/add', this.challenge);
          this.challengeId = resp.challenge_id;
        }
        this.$emit('submitCallback', this.challengeId);
        this.$router.push('/admin/challenges/');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    cancel() {
      this.$router.push('/admin/challenges/');
    },
  },
  async mounted() {
    if (!this.isEditing) return;
    try {
      const resp = await this.$ctforge.api.get(`/challenges/${encodeURIComponent(this.challengeName)}`);
      // Manually copy because response contains extra fields
      this.challenge = {
        name: resp.name,
        description: resp.description,
        markdown: resp.markdown,
        flag: resp.flag,
        points: resp.points,
        tags: resp.tags,
        submission_starting_time: new Date(resp.submission_starting_time),
        submission_ending_time: new Date(resp.submission_ending_time),
        active: resp.active,
        scoring_parameter: resp.scoring_parameter,
        scoring_dynamic_minimum: resp.scoring_dynamic_minimum,
        scoring_dynamic_decay: resp.scoring_dynamic_decay,
        required: resp.required,
      };
      this.challengeId = resp.challenge_id;
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
  computed: {
    isDynamic() {
      return this.challenge.scoring_parameter === 'dynamic';
    },
    markdowPreview() {
      return marked(this.challenge.description);
    },
  },
};
</script>

<style>
</style>
