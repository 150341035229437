import AdminDocker from './components/AdminDocker.vue';

export default {

  install(vue) {
    // Admin interface
    vue.$router.addChildrenRoutes('/admin', [
      {
        path: 'docker',
        component: AdminDocker,
        meta: { requiresAdmin: true },
      },
    ]);
    vue.$ctforge.addAdminLinks([
      { name: 'Docker', path: '/admin/docker', icon: 'server' },
    ]);
  },

};
