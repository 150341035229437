import Vue from 'vue';
import VueRouter from 'vue-router';

// Include Buefy components
import Buefy from 'buefy';
// Custom Bulma theme
// eslint-disable-next-line import/no-unresolved
import '@/scss/style.scss';
// Fontawesome icons
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';

// Our App
import App from './App.vue';

// ROUTES
import Page from './components/Page.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import UserProfile from './components/UserProfile.vue';
import Admin from './components/admin/Admin.vue';
import AdminHome from './components/admin/AdminHome.vue';
import AdminUsers from './components/admin/AdminUsers.vue';
import AdminUserForm from './components/admin/AdminUserForm.vue';
import AdminRoles from './components/admin/AdminRoles.vue';
import AdminRoleForm from './components/admin/AdminRoleForm.vue';
import AdminConfig from './components/admin/AdminConfig.vue';
import AdminPageEditor from './components/admin/AdminPageEditor.vue';

// The dynamic router fixes https://github.com/vuejs/vue-router/issues/1234
// Allowing us to have an `addRoutes` that overwrites previously defined mappings
// `DynamicRouter.createRouter` has the same behavior as `new VueRouter({ routes: default_routes })`
import DynamicRouter from './dynamic-router';

// Load plugins
import Plugins from './plugins';

Vue.use(VueRouter);
Vue.use(Buefy, {
  defaultIconPack: 'fas',
});
Vue.config.productionTip = false;

// eslint-disable-next-line camelcase
const default_routes = [
  { path: '/register', component: Register },
  { path: '/login', component: Login },
  {
    path: '/user',
    component: UserProfile,
    meta: { requiresLogin: true },
  },
  {
    path: '/admin',
    component: Admin,
    meta: { requiresAdmin: true },
    children: [
      { path: '', component: AdminHome },
      { path: 'users', component: AdminUsers },
      {
        name: 'admin_users_add',
        path: 'users/add',
        component: AdminUserForm,
        props: { isEditing: false },
      },
      {
        name: 'admin_users_edit',
        path: 'users/edit/:mail',
        component: AdminUserForm,
        props: { isEditing: true },
      },
      { path: 'roles', component: AdminRoles },
      {
        path: 'roles/add',
        component: AdminRoleForm,
        props: { isEditing: false },
      },
      {
        path: 'roles/edit/:id',
        component: AdminRoleForm,
        props: { isEditing: true },
      },
      { path: 'config', component: AdminConfig },
      {
        path: 'pages/edit',
        component: AdminPageEditor,
        props: (route) => ({ pageRoute: route.query.p }),
      },
    ],
  },
  {
    path: '*',
    component: Page,
    props: (route) => ({ route: route.path }),
  },
];
const router = DynamicRouter.createRouter(default_routes);

// CTForge object (store pattern + apis)
const CTFORGE_URL = ''; // http://127.0.0.1:8000';

const ctforge = {
  // state
  state: {
    topbar: [], // { name: 'Scoreboard', path: '/scoreboard', loginRequired: true },
    adminbar: [
      { name: 'Users', path: '/admin/users', icon: 'users' },
      { name: 'Roles', path: '/admin/roles', icon: 'user-lock' },
    ],
    userinfo: null,
    loggedin: false,
    config: {
      REGISTRATION_ENABLED: false,
    },
  },
  // eslint-disable-next-line prefer-spread
  addTopbarLinks(options) { this.state.topbar.push.apply(this.state.topbar, options); },
  // eslint-disable-next-line prefer-spread
  addAdminLinks(options) { this.state.adminbar.push.apply(this.state.adminbar, options); },
  loadUserinfo() {
    const val = localStorage.getItem('userinfo');
    if (val !== null) this.state.userinfo = JSON.parse(val);
    this.state.loggedin = val !== null;
  },
  setUserinfo(payload) {
    if (payload === null) {
      localStorage.removeItem('userinfo');
      this.state.userinfo = null;
      this.state.loggedin = false;
      return;
    }
    localStorage.setItem('userinfo', JSON.stringify(payload));
    this.state.userinfo = payload;
    this.state.loggedin = true;
  },
  // actions
  async loadConfig() {
    const val = localStorage.getItem('config');
    if (val !== null) this.state.config = JSON.parse(val);
    else {
      const res = await this.api.get('/config');
      localStorage.setItem('config', JSON.stringify(res));
      this.state.config = res;
    }
  },
  async checkLogin() {
    try {
      const userinfo = await this.api.get('/users/info');
      this.setUserinfo(userinfo);
      return true;
    } catch (e) {
      this.setUserinfo(null);
      return false;
    }
  },
  async logout() {
    try {
      const res = await this.api.post('/users/logout');
      if (!res) throw Error();
      this.setUserinfo(null);
      return true;
    } catch (e) {
      // for example if unauthorized
      this.setUserinfo(null);
      return false;
    }
  },
  // api
  api: {
    async get(endpoint) {
      const res = await fetch(`${CTFORGE_URL}${endpoint}`, {
        credentials: 'include',
      });
      // We assume here that every error is a json
      const js = await res.json();
      if (res.status >= 200 && res.status <= 299) {
        return js;
      }
      const err = new Error(js.message);
      err.status_code = res.status;
      throw err;
    },
    async post(endpoint, data) {
      const res = await fetch(`${CTFORGE_URL}${endpoint}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'X-SEC-CTFORGE': 'csrf-protection',
        },
      });
      // We assume here that every error is a json
      const js = await res.json();
      if (res.status >= 200 && res.status <= 299) {
        return js;
      }
      const err = new Error(js.message);
      err.status_code = res.status;
      throw err;
    },
  },
};

Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.ctforge) this.$ctforge = options.ctforge;
    else if (options.parent && options.parent.$ctforge) this.$ctforge = options.parent.$ctforge;
  },
});

// Utils
Vue.mixin({
  beforeCreate() {
    // Common methods
    // eslint-disable-next-line func-names
    this.notify = function (msg, type = 'is-info', callback = null) {
      const notif = this.$buefy.notification.open({
        message: msg,
        type,
        hasIcon: true,
        queue: false,
      });
      if (callback) notif.$on('close', callback);
    };
  },
});

// VUE
const vue = new Vue({
  router,
  ctforge,
  data: ctforge.state, // reactive state
  render: (h) => h(App),
}).$mount('#app');

vue.$router.beforeEach((to, from, next) => {
  // Check if the route requires admin privileges, and if so redirect to login page
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!vue.$ctforge.state.loggedin || !vue.$ctforge.state.userinfo.admin) {
      next({ path: '/login' });
      vue.notify('This page requires an admin account', 'is-danger');
      return;
    }
  }
  // Check if the route requires a session, and if so redirect to login page
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!vue.$ctforge.state.loggedin) {
      next({ path: '/login' });
      vue.notify('Log in first!', 'is-danger');
      return;
    }
  }
  next();
});
Plugins.install(vue);
