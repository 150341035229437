import UserProfileMattermost from './components/UserProfileMattermost.vue';
import AdminMattermost from './components/AdminMattermost.vue';

export default {

  install(vue) {
    const userRecord = vue.$router.options.routes.filter((e) => e.path === '/user')[0];
    const userComponent = userRecord.component;
    const userComponentProps = userRecord.props;

    vue.$router.addRoutes([
      {
        path: '/user',
        component: UserProfileMattermost,
        props: { baseComponent: userComponent, baseComponentProps: userComponentProps },
        meta: { requiresLogin: true },
      },
    ]);
    vue.$router.addChildrenRoutes('/admin', [
      { path: 'mattermost', component: AdminMattermost },
    ]);
    vue.$ctforge.addAdminLinks([
      { name: 'Mattermost', path: '/admin/mattermost', icon: 'comments' },
    ]);
  },

};
