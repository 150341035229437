<template>
  <div>

    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
           <b-icon icon="server" /> Containers
        </p>
        <span class="card-header-icon" aria-label="more options">
          <b-button type="is-link is-small" :loading="loading" icon-left="sync" @click="refresh"
                    :disabled="!!checkedContainers.length || !!checkedNetworks.length">Refresh</b-button>
        </span>
      </header>

      <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

      <div class="card-content" v-else>
        <div class="content">

          <nav class="level">
            <!-- Left side -->
            <div class="level-left">

              <div class="level-item">
                <div class="buttons has-addons">
                  <b-button :disabled="!checkedContainers.length" @click="executeCheckedContainers('start')" icon-left="play" class="is-info">Start</b-button>
                  <b-button :disabled="!checkedContainers.length" @click="executeCheckedContainers('stop')" icon-left="stop" class="is-info">Stop</b-button>
                  <b-button :disabled="!checkedContainers.length" @click="executeCheckedContainers('kill')" icon-left="bomb" class="is-danger">Kill</b-button>
                  <b-button :disabled="!checkedContainers.length" @click="executeCheckedContainers('restart')" icon-left="sync" class="is-info">Restart</b-button>
                  <b-button :disabled="!checkedContainers.length" @click="executeCheckedContainers('remove')" icon-left="trash" class="is-danger">Remove</b-button>
                </div>
              </div>
              <div class="level-item">
                <b-button icon-left="plus" @click="addContainer" class="is-info">Add Container</b-button>
              </div>

            </div>
            <div class="level-right">

              <div class="level-item">
                <p class="subtitle is-5">
                  <strong>{{ this.containers.length }}</strong> Containers
                </p>
              </div>
            </div>
          </nav>

          <!-- :checked-rows.sync="checkedRows"  -->
          <b-table
            :data="containers"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedContainers"
            custom-row-key="id">

            <b-table-column field="name" label="Name" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="state" label="State" centered sortable v-slot="props">
              <span class="tag is-success" v-if="props.row.state == 'running'">
                <b>{{ props.row.state.replace(/^\w/, c => c.toUpperCase()) }}</b></span>
              <span class="tag is-danger"
                    v-else-if="props.row.state == 'stopped' || props.row.state == 'exited'">
                <b>{{ props.row.state.replace(/^\w/, c => c.toUpperCase()) }}</b></span>
              <span class="tag is-info" v-else ><b>{{ props.row.state }}</b></span>
            </b-table-column>

            <b-table-column label="Actions" centered v-slot="props">
              <a @click="logsContainer(props.row.id)"><b-icon icon="file-alt"/></a>
              <a @click="inspectContainer(props.row.id)"><b-icon icon="info-circle"/></a>
              <!-- <span v-if="props.row.state == 'running'">
                <a><b-icon icon="chart-area"/></a>
                <a><b-icon icon="terminal"/></a>
              </span> -->
            </b-table-column>
            <b-table-column field="image" label="Image" sortable v-slot="props">
              <span v-if="props.row.image">{{ props.row.image }}</span>
              <span v-else><b>-</b></span>
            </b-table-column>

            <b-table-column field="created" label="Created" sortable v-slot="props">
              {{ new Date(props.row.created).toLocaleString() }}
            </b-table-column>

            <b-table-column field="ip" label="IP Address" sortable v-slot="props">
              <span v-if="props.row.ip">{{ props.row.ip }}</span>
              <span v-else><b>-</b></span>
            </b-table-column>

            <b-table-column field="networks" label="Networks" sortable v-slot="props">
              <span class="tag is-info ml-2" v-for="net in props.row.networks" :key="net">
                {{ net }}
              </span>
            </b-table-column>

            <b-table-column field="ports" label="Published Ports" sortable v-slot="props">
              <span v-if="props.row.ports.length">
                <span class="tag is-info ml-2" v-for="prt in props.row.ports" :key="prt">
                  {{ prt }}
                </span>
              </span>
              <span v-else><b>-</b></span>
            </b-table-column>

          </b-table>
        </div>
      </div>
    </div>

        <div class="card mt-4">
      <header class="card-header">
        <p class="card-header-title">
           <b-icon icon="network-wired" /> Networks
        </p>
      </header>

      <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

      <div class="card-content" v-else>
        <div class="content">

          <nav class="level">
            <!-- Left side -->
            <div class="level-left">

              <div class="level-item">
                <div class="buttons has-addons">
                  <b-button :disabled="!checkedNetworks.length" @click="removeCheckedNetworks()" icon-left="trash" class="is-danger">Remove</b-button>
                </div>
              </div>
              <div class="level-item">
                <b-button icon-left="plus" @click="addNetwork" class="is-info">Add Network</b-button>
              </div>

            </div>
            <div class="level-right">

              <div class="level-item">
                <p class="subtitle is-5">
                  <strong>{{ this.networks.length }}</strong> Networks
                </p>
              </div>
            </div>
          </nav>

          <!-- :checked-rows.sync="checkedRows"  -->
          <b-table
            :data="networks"
            checkable
            checkbox-position="left"
            :is-row-checkable="(row) => !['host', 'bridge', 'none'].includes(row.name)"
            :checked-rows.sync="checkedNetworks"
            custom-row-key="id">

            <b-table-column field="name" label="Name" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="driver" label="Driver" sortable v-slot="props">
              {{ props.row.driver }}
            </b-table-column>
            <b-table-column field="internal" label="Internal" sortable v-slot="props">
              {{ props.row.internal }}
            </b-table-column>

            <b-table-column field="subnet" label="Subnet" sortable v-slot="props">
              <span v-if="props.row.subnet">{{ props.row.subnet }}</span>
              <span v-else><b>-</b></span>
            </b-table-column>
            <b-table-column field="gateway" label="Gateway" sortable v-slot="props">
              <span v-if="props.row.gateway">{{ props.row.gateway }}</span>
              <span v-else><b>-</b></span>
            </b-table-column>

          </b-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';

const InspectView = {
  props: ['attrs'],
  components: {
    JsonViewer,
  },
  template: `
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="info-circle" />
                 Inspect Container
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body">
              <json-viewer :value="attrs" :expand-depth=1 boxed expanded sort></json-viewer>
           </section>
       </div>
`,
};

const LogsView = {
  props: ['logs'],
  template: `
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="info-circle" />
                 Container Logs
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body" ref='output'>
              <pre class="box has-background-light"><code>{{ logs }}</code></pre>
           </section>
       </div>
`,
  mounted() {
    const elm = this.$refs.output;
    elm.scrollTop = elm.scrollHeight;
  },
};

const AddContainerForm = {
  props: ['networks'],
  template: `
   <form @submit.prevent="deploy">
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="plus-circle" />
                 Add Container
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body">

               <b-progress v-if="loading" />

               <b-field label="Name">
                   <b-input
                       type="text"
                       v-model="name"
                       :disabled="loading"
                       placeholder="my-container">
                   </b-input>
               </b-field>

               <b-field label="Image">
                   <b-input
                       type="text"
                       v-model="image"
                       :disabled="loading"
                       placeholder="registry:port/my-image:my-tag"
                       required>
                   </b-input>
               </b-field>

               <b-field label="Command">
                   <b-input
                       type="text"
                       v-model="command"
                       :disabled="loading"
                       placeholder="bash">
                   </b-input>
               </b-field>

               <b-field label="Network">
                   <b-select v-model="network" expanded placeholder="Select a network">
                       <option
                           v-for="option in networks"
                           :value="option.name"
                           :key="option.name">
                           {{ option.name }}
                       </option>
                   </b-select>
               </b-field>

               <b-field label="Publish Ports">
                   <b-taginput
                       :disabled="loading"
                       v-model="ports"
                       type="is-info"
                       icon="ethernet"
                       placeholder="127.0.0.1:8080:80/tcp"
                       aria-close-label="Delete"
                       :before-adding="validatePortMapping" />
               </b-field>

               <b-field class="mt-5">
                   <b-checkbox :disabled="loading" v-model="auto_remove">Auto remove (--rm)</b-checkbox>
               </b-field>
               <b-field>
                   <b-checkbox :disabled="loading" v-model="tty">TTY (-t)</b-checkbox>
               </b-field>

           </section>
           <footer class="modal-card-foot">
               <b-button
                   label="Close"
                   expanded
                   @click="$emit('close')" />
               <b-button
                   label="Deploy"
                   native-type="submit"
                   expanded
                   type="is-primary" />
           </footer>
       </div>
   </form>
`,
  data() {
    return {
      name: null,
      image: null,
      command: null,
      network: null,
      auto_remove: false,
      tty: false,
      ports: [],
      // TODO: labels
      loading: false,
    };
  },
  methods: {
    async deploy() {
      this.loading = true;
      try {
        const params = {};
        ['name', 'image', 'command', 'network', 'auto_remove', 'tty', 'ports'].forEach((name) => {
          params[name] = this[name];
        });
        await this.$ctforge.api.post('/admin/docker/container/run', params);
      } catch (e) {
        this.notify(e.message, 'is-danger');
        this.loading = false;
      }
      this.loading = false;
      this.$emit('close');
    },
    validatePortMapping(port) {
      return !!((/([0-9.]+:)?(\d{1,5}):(\d{1,5})(\/tcp|\/udp)?/).exec(port));
    },
  },
};

const AddNetworkForm = {
  template: `
   <form @submit.prevent="create">
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="network-wired" />
                 Add Network
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body">

               <b-progress v-if="loading" />

               <b-field label="Name">
                   <b-input
                       type="text"
                       v-model="name"
                       :disabled="loading"
                       placeholder="my-network"
                       required>
                   </b-input>
               </b-field>

               <b-field label="Driver">
                   <b-input
                       type="text"
                       v-model="driver"
                       :disabled="loading"
                       placeholder="bridge">
                   </b-input>
               </b-field>

               <b-field label="Subnet">
                   <b-input
                       type="text"
                       v-model="subnet"
                       :disabled="loading"
                       placeholder="10.13.37.0/24">
                   </b-input>
               </b-field>

               <b-field label="Gateway">
                   <b-input
                       type="text"
                       v-model="gateway"
                       :disabled="loading"
                       placeholder="10.13.37.254">
                   </b-input>
               </b-field>

               <b-field class="mt-5">
                   <b-checkbox :disabled="loading" v-model="internal">Internal</b-checkbox>
               </b-field>

           </section>
           <footer class="modal-card-foot">
               <b-button
                   label="Close"
                   expanded
                   @click="$emit('close')" />
               <b-button
                   label="Create"
                   native-type="submit"
                   expanded
                   type="is-primary" />
           </footer>
       </div>
   </form>
`,
  data() {
    return {
      name: null,
      driver: null,
      subnet: null,
      gateway: null,
      internal: false,

      loading: false,
    };
  },
  methods: {
    async create() {
      this.loading = true;
      try {
        const params = {};
        ['name', 'driver', 'subnet', 'gateway', 'internal'].forEach((name) => {
          params[name] = this[name];
        });
        await this.$ctforge.api.post('/admin/docker/network/create', params);
      } catch (e) {
        this.notify(e.message, 'is-danger');
        this.loading = false;
      }
      this.loading = false;
      this.$emit('close');
    },
  },
};

export default {
  data() {
    return {
      containers: [],
      networks: [],
      checkedContainers: [],
      checkedNetworks: [],
      unauthorized: false,
      loading: false,
      interval: null,
    };
  },
  methods: {
    async refresh() {
      // Prevent updating when checked: FIXME, find a way to update checked when updating table data
      if (this.checkedContainers.length || this.checkedNetworks.length) return;

      this.loading = true;
      try {
        this.networks = await this.$ctforge.api.get('/admin/docker/networks');
        this.containers = await this.$ctforge.api.get('/admin/docker/containers');
        this.loading = false;
      } catch (e) {
        if (e.status_code === 401) {
          this.unauthorized = true;
        } else this.notify(e.message, 'is-danger');
        this.loading = false;
      }
    },
    clearSelection() {
      this.checkedContainers = [];
      this.checkedNetworks = [];
    },
    async inspectContainer(id) {
      const loading = this.$buefy.loading.open();
      const attrs = await this.$ctforge.api.get(`/admin/docker/container/${id}/inspect`);
      loading.close();
      this.$buefy.modal.open({
        parent: this,
        component: InspectView,
        hasModalCard: true,
        trapFocus: false,
        props: { attrs },
      });
    },
    async logsContainer(id) {
      const loading = this.$buefy.loading.open();
      try {
        const logs = await this.$ctforge.api.get(`/admin/docker/container/${id}/logs`);
        loading.close();
        this.$buefy.modal.open({
          parent: this,
          component: LogsView,
          hasModalCard: true,
          trapFocus: false,
          props: { logs: logs.message },
        });
      } catch (e) {
        loading.close();
        this.notify(e.message, 'is-danger');
      }
    },
    async commandContainer(id, command) {
      const loading = this.$buefy.loading.open();
      try {
        await this.$ctforge.api.post(`/admin/docker/container/${id}/${command}`);
        loading.close();
        this.notify(`Container ${id} ${command === 'stop' ? 'stopp' : command}ed.`);
      } catch (e) {
        loading.close();
        this.notify(e.message, 'is-danger');
      }
    },
    async executeCheckedContainers(command) {
      await Promise.all(this.checkedContainers.map((cnt) => this.commandContainer(cnt.id, command)));
      this.clearSelection();
      this.refresh();
    },
    async removeNetwork(id) {
      const loading = this.$buefy.loading.open();
      try {
        await this.$ctforge.api.post(`/admin/docker/network/${id}/remove`);
        loading.close();
        this.notify(`Network ${id} removed.`);
      } catch (e) {
        loading.close();
        this.notify(e.message, 'is-danger');
      }
    },
    async removeCheckedNetworks() {
      await Promise.all(this.checkedNetworks.map((net) => this.removeNetwork(net.id)));
      this.clearSelection();
      this.refresh();
    },
    addContainer() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: AddContainerForm,
        hasModalCard: true,
        trapFocus: false,
        props: { networks: this.networks },
      });
      modal.$on('close', () => {
        this.clearSelection();
        this.refresh();
      });
    },
    addNetwork() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: AddNetworkForm,
        hasModalCard: true,
        trapFocus: false,
      });
      modal.$on('close', () => {
        this.clearSelection();
        this.refresh();
      });
    },
  },
  mounted() {
    this.refresh();
    this.interval = setInterval(() => this.refresh(), 10000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
};

</script>

<style lang="scss">
@import '@/scss/_variables.scss';
.jv-light {
  background: $grey-lighter !important;
}
.jv-light .jv-ellipsis {
  background: $grey-light !important;
}
.has-background-light {
  background: $grey-lighter !important;
}
</style>
