<template>
<div class="card">
  <header class="card-header">
    <p class="card-header-title">
      <span v-if="isEditing">
        <b-icon icon="edit" />
        Edit User
      </span>
      <span v-else>
        <b-icon icon="user-plus" />
        Add User
      </span>
    </p>
    <span class="card-header-icon" aria-label="back">
      <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
    </span>
  </header>
  <div class="card-content">
    <div class="content">

      <form @submit.prevent="submit">
        <b-field label="Name">
          <b-input type="text" required v-model="user.name"></b-input>
        </b-field>

        <b-field label="Surname">
          <b-input type="text" required v-model="user.surname"></b-input>
        </b-field>

        <b-field label="Nickname">
          <b-input type="text" v-model="user.nickname"></b-input>
        </b-field>

        <b-field label="Affiliation">
          <b-input type="text" v-model="user.affiliation"></b-input>
        </b-field>

        <b-field label="E-Mail">
          <b-input type="email" required v-model="user.mail"></b-input>
        </b-field>

        <b-field label="MN">
          <b-input type="text" v-model="user.mn"></b-input>
        </b-field>

        <b-field label="Password">
          <b-input type="password" v-model="user.password"></b-input>
        </b-field>

        <b-field>
          <b-checkbox v-model="user.active">Active</b-checkbox>
        </b-field>

        <b-field label="Roles">
          <multiselect
            v-model="user.roles"
            :options="allRoles"
            :multiple="true"
            :custom-label="roleLabel"
            placeholder="Roles of this user"
            label="id"
            track-by="id"></multiselect>
        </b-field>

        <slot name="extras"></slot>

        <b-field grouped>
          <b-button native-type="submit" class="is-primary">Save</b-button>
          <b-button class="ml-2" @click="cancel">Cancel</b-button>
        </b-field>
      </form>
    </div>
  </div>
</div>

</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      currentMail: null,
      user: {
        name: null,
        surname: null,
        nickname: null,
        affiliation: null,
        mail: null,
        mn: null,
        password: null,
        active: true,
        roles: [],
      },
      allRoles: [],
    };
  },
  methods: {
    async submit() {
      // browser takes care of validation using required fields (works because we're using onSubmit)
      try {
        const payload = { ...this.user };
        if (!payload.password) {
          // only set password in payload if changing it
          delete payload.password;
        }
        payload.roles = payload.roles.map((r) => r.id);

        if (this.isEditing) {
          await this.$ctforge.api.post('/admin/users/edit', {
            // User is fetched by email, send backend the current (before editing) email
            current_mail: this.currentMail,
            ...payload,
          });
        } else {
          await this.$ctforge.api.post('/admin/users/add', payload);
        }
        this.$router.push('/admin/users/');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    cancel() {
      this.$router.push('/admin/users/');
    },
    async loadAllRoles() {
      try {
        this.allRoles = await this.$ctforge.api.get('/admin/acs/roles/list');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    roleLabel(role) {
      return role.name;
    },
  },
  async mounted() {
    await this.loadAllRoles();
    if (!this.isEditing) {
      return;
    }
    try {
      const { mail } = this.$route.params;
      const resp = await this.$ctforge.api.get(`/admin/users/get/${encodeURIComponent(mail)}`);
      // Manually copy because response contains extra fields
      this.user = {
        name: resp.name,
        surname: resp.surname,
        nickname: resp.nickname,
        affiliation: resp.affiliation,
        mail: resp.mail,
        mn: resp.mn,
        password: null,
        active: resp.active,
        roles: resp.roles,
      };
      this.currentMail = resp.mail;
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>

<style>
</style>
