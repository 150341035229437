<template>
<div class="columns">
  <div class="column is-one-third">
    <h1 class="pb-5 has-text-weight-bold is-size-3">Register</h1>
    <form @submit.prevent="register">
      <b-field label="Name">
        <b-input type="text" required v-model="user.name"></b-input>
      </b-field>
      <b-field label="Surname">
        <b-input type="text" required v-model="user.surname"></b-input>
      </b-field>
      <b-field label="Nickname">
        <b-input type="text" required v-model="user.nickname"></b-input>
      </b-field>
      <b-field label="Affiliation">
        <b-input type="text" v-model="user.affiliation"></b-input>
      </b-field>
      <b-field label="Email">
        <b-input ref="email" type="email" v-model="user.mail" placeholder="nobody@nowhere.com" expanded required></b-input>
      </b-field>
      <b-field label="Password">
        <b-input ref="password" type="password" v-model="user.password" placeholder="password" expanded password-reveal required></b-input>
      </b-field>
      <b-field label="Confirm password" :type="confirmType" :message="confirmMsg">
        <b-input ref="password" type="password" v-model="password2" placeholder="password" expanded password-reveal required></b-input>
      </b-field>

      <b-field>
        <p class="">
          <b-button type="is-primary" native-type="submit">
            Register
          </b-button>
        </p>
      </b-field>

      <slot name="buttons"></slot>
    </form>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        name: null,
        surname: null,
        nickname: null,
        affiliation: null,
        mail: null,
        password: null,
      },
      password2: '',
    };
  },
  computed: {
    isLoggedin() {
      return this.$ctforge.loggedin;
    },
    confirmType() {
      return this.user.password === this.password2 ? '' : 'is-danger';
    },
    confirmMsg() {
      return this.user.password === this.password2 ? '' : 'Passwords must match';
    },
  },
  methods: {
    async register() {
      try {
        const res = await this.$ctforge.api.post('/users/register', this.user);
        this.notify(res.message);
        this.$router.push('/login');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
  mounted() {
    if (this.isLoggedin) this.$router.push('/');
    document.title = 'Register';
  },
};
</script>

<style>
</style>
