<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="users-slash" /> Hidden Users
      </p>
    </header>

    <div class="card-content" v-if="unauthorized">
      <div class="content">
        <p class="has-text-centered has-text-weight-bold is-size-4">
          <b-icon icon="exclamation-triangle" /> <br/>
          Unauthorized!
        </p>
      </div>
    </div>

    <div class="card-content" v-else>
      <div class="content">

        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ data.length }}</strong> Hidden Users
              </p>
            </div>
          </div>
          <div class="level-right">
            <p class="level-item">
              <b-autocomplete
                placeholder="Mail"
                v-model="selected_mail"
                :data="filteredUsers"
                field="mail"
                @select="option => (selected = option)"
                :clearable="true"
                :append-to-body="true">
                <template #empty>No results for {{ selected_mail }}</template>
              </b-autocomplete>
              <b-button class="ml-3" icon-left="plus" @click="selected? hideUser(selected.mail, true) : void(0)">
                Hide User
              </b-button>
            </p>
          </div>
        </nav>

        <b-table
          :data="data"
          hoverable
          default-sort="mail">
          <b-table-column field="mail" label="Mail" sortable v-slot="props">
            {{ props.row.mail }}
          </b-table-column>
          <b-table-column label="Actions" width="100" centered v-slot="props">
            <a>
              <b-button icon-left="trash" @click="hideUser(props.row.mail, false);refresh()"/>
            </a>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      unauthorized: false,
      data: [],
      users: [],
      selected: null,
      selected_mail: '',
    };
  },
  methods: {
    async refresh() {
      try {
        this.users = await this.$ctforge.api.get('/admin/users/list');
        this.data = await this.$ctforge.api.get('/admin/user/hidden_list');
      } catch (e) {
        this.notify(e, 'is-error');
      }
    },
    async hideUser(email, hidden) {
      try {
        this.$ctforge.api.post('/admin/users/hide', {
          is_hidden: hidden,
          mail: email,
        });
        this.refresh();
      } catch (e) {
        this.notify(e, 'is-error');
      }
    },
  },
  computed: {
    filteredUsers() {
      return this.users.filter((u) => u.mail.toString().toLowerCase().indexOf(this.selected_mail.toLowerCase()) >= 0);
    },
  },
  async mounted() {
    await this.refresh();
  },
};
</script>
<style>
</style>
