<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="edit" />
        Writeup Evaluation
      </p>
      <span class="card-header-icon" aria-label="back">
          <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
      </span>
    </header>
    <div class="card-content">
      <div class="content">
        <table class="table is-fullwidth is-bordered">
          <tbody>
            <tr>
              <td><b-icon class="pr-3" icon="user"/> User</td> <td> <b>{{ user.name }} {{ user.surname }} ({{ user.nickname }})</b> </td>
            </tr>
            <tr>
              <td width="160"><b-icon class="pr-3" icon="envelope"/> Mail</td> <td>{{ user.mail }}</td>
            </tr>
            <tr>
              <td width="160"><b-icon class="pr-3" icon="th-list"/> Challenge</td> <td>{{ challenge_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <writeupForm :user_id="parseInt(user_id)" :edit_evaluation="true" />
</div>
</template>

<script>
import WriteupForm from '../WriteupForm.vue';

export default {
  name: 'AdminEvaluation',
  components: {
    WriteupForm,
  },
  data() {
    return {
      user_id: this.$route.params.user_id,
      challenge_name: this.$route.params.challenge_name,
      user: {},
    };
  },
  methods: {
  },
  async mounted() {
    try {
      this.user = await this.$ctforge.api.get(`/admin/users/get-by-id/${this.user_id}`);
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },

};
</script>

<style>
</style>
