<template>
  <div>
    <component :is="baseComponent" v-bind="baseComponentProps"></component>
    <div class="card mt-5">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="key" /> SSH Keys
      </p>
      <span class="card-header-icon" aria-label="more options">
        <b-button type="is-danger is-small" icon-left="trash" @click="clear"
                  :disabled="!key_list.length">Clear</b-button>
        </span>

    </header>
     <div class="card-content">
       <div class="content">

         <section>
           <form @submit.prevent="upload">
           <b-field grouped label="Public Key" labelPosition="on-border">
             <b-input v-model="key" pattern="ssh-.*" expanded></b-input>
             <b-button icon-left="plus" native-type="submit">
               Save Key
             </b-button>
           </b-field>
           </form>
         </section>
         <section :class="key_list.length>0 ? 'mt-5' : ''">
             <pre class="box is-size-7 is-family-monospace" v-for="key in key_list" :key="key"><code>{{ key }}</code></pre>
         </section>
       </div>
     </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    baseComponent: { required: true },
    baseComponentProps: { required: true },
  },
  data() {
    return {
      key_list: [],
      key: null,
    };
  },
  methods: {
    async refresh() {
      try {
        this.key_list = await this.$ctforge.api.get('/ssh/keys');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async upload() {
      if (!this.key || this.key.length < 1) return;
      try {
        await this.$ctforge.api.post('/ssh/keys/add', { publicKey: this.key });
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      await this.refresh();
      this.key = null;
    },
    async clear() {
      this.$buefy.dialog.confirm({
        title: 'Delete all SSH keys',
        message: 'Are you sure you want to <b>delete</b> all your uploaded keys? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$ctforge.api.post('/ssh/keys/clear');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
          await this.refresh();
        },
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>
</style>
