<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="users" /> Users
      </p>
    </header>

      <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

    <div class="card-content" v-else>
      <div class="content">

        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ data.length }}</strong> Users
              </p>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-switch v-model="searchable"><b-icon icon="search"/></b-switch>
            </div>
            <p class="level-item">
              <b-button icon-left="user-plus" tag="router-link"
                        to="/admin/users/add">
                Add User
              </b-button>
            </p>
          </div>
        </nav>

        <b-table
          :data="data"
          hoverable
          default-sort="id">

          <b-table-column field="name" label="Name" sortable :searchable=searchable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="surname" label="Surname" sortable :searchable=searchable v-slot="props">
            {{ props.row.surname }}
          </b-table-column>
          <b-table-column field="nickname" label="Nick" sortable :searchable=searchable v-slot="props">
            <b-icon icon="minus" v-if="!props.row.nickname" />
            {{ props.row.nickname }}
          </b-table-column>
          <b-table-column field="mail" label="Mail" sortable :searchable=searchable v-slot="props">
            {{ props.row.mail }}
          </b-table-column>
          <b-table-column field="mn" label="MN" sortable :searchable=searchable v-slot="props">
            <b-icon icon="minus" v-if="!props.row.mn" />
            {{ props.row.mn }}
          </b-table-column>
          <b-table-column field="affiliation" label="Affiliation" sortable v-slot="props">
            <b-icon icon="minus" v-if="!props.row.affiliation" />
            {{ props.row.affiliation }}
          </b-table-column>
          <b-table-column field="active" label="Active" width="40" centered v-slot="props">
            <b-icon icon="check" v-if="props.row.active"/>
            <b-icon icon="times" v-else />
          </b-table-column>
          <b-table-column field="admin" label="Admin" width="40" centered v-slot="props">
            <b-icon icon="check" v-if="props.row.admin"/>
            <b-icon icon="times" v-else />
          </b-table-column>

          <b-table-column label="Actions" width="100" centered v-slot="props">
            <router-link :to="{
              name: 'admin_users_edit',
              params: {
                mail: props.row.mail
              },
            }">
              <b-icon class="pr-3" icon="edit"/>
            </router-link>
            <a v-on:click="deleteUser(props.row.mail)">
              <b-icon class="pl-3" icon="trash" />
            </a>
          </b-table-column>

        </b-table>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      searchable: false,
      unauthorized: false,
    };
  },
  methods: {
    deleteUser(mail) {
      this.$buefy.dialog.confirm({
        title: 'Deleting User',
        message: `Are you sure you want to <b>delete</b> user '${mail}'? This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$ctforge.api.post('/admin/users/delete', {
              mail,
            });
            await this.loadUsers();
            this.notify('User Deleted', 'is-info');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    async loadUsers() {
      try {
        this.data = await this.$ctforge.api.get('/admin/users/list');
      } catch (e) {
        if (e.status_code === 401) {
          this.unauthorized = true;
        } else this.notify(e.message, 'is-danger');
      }
    },
  },
  async mounted() {
    await this.loadUsers();
  },
};
</script>

<style>
</style>
