export default {

  install(vue, ctforge) {
    const requireContext = require.context('../plugins/', true, /\.js$/);
    requireContext.keys().sort().forEach((filename) => {
      const plugin = requireContext(filename);
      if (plugin.default.install) {
        plugin.default.install(vue, ctforge);
        // eslint-disable-next-line no-console
        console.log(`Plugin loaded: ${filename}`);
      }
    });
  },

};
