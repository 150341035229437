<template>
<div class="container">
  <h2 class="is-clearfix is-size-3">
    <b-icon icon="check-circle" v-if="data.solved" />
    <b-icon icon="times-circle" v-if="data.givenup" />
    {{data.name}}
    <span class="text-muted">({{data.points}} points)</span>
    <span class="small is-pulled-right">{{data.tags}}</span>
  </h2>

  <div class="card mt-2">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="align-justify" />
        Description
      </p>
      <span class="card-header-icon" aria-label="more options" v-if="data.submission_ending_time">
        <b-icon icon="clock"/>
        {{ new Date(data.submission_ending_time) }}
      </span>
    </header>
    <div class="card-content">
      <div class="content">
        <div v-html="data.description" v-if="!data.markdown"></div>
        <div v-html="markdownDescription" v-else></div>
      </div>
    </div>
  </div>

  <div class="box has-text-centered mt-5 pl-6 pr-6 ml-6 mr-6" v-if="(new Date()) < (new Date(data.submission_starting_time))">
   Flag submission will be enabled on {{ new Date(data.submission_starting_time) }}
  </div>
  <div v-else-if="!data.solved && !data.givenup" class="mt-5 pl-6 pr-6 ml-6 mr-6">
    <b-field label="Flag" label-position="on-border" grouped>
      <b-input ref="flag" type="text" v-model='flag' placeholder="Flag" v-on:keyup.native.enter="submit" expanded></b-input>
      <b-button type="is-primary" icon-left="check-circle" @click="submit">Submit</b-button>
      <b-button class="ml-3 is-pulled-right" icon-left="times-circle" type="is-danger" @click="giveup" v-if="(new Date()) < (new Date(data.submission_ending_time))">Give Up</b-button>
    </b-field>

  </div>
  <div v-else>
    <slot name="writeups"></slot>
  </div>
</div>
</template>

<script>
import marked from 'marked';

export default {
  data() {
    return {
      name: this.$route.params.challenge_name,
      flag: '',
      data: {},
    };
  },
  methods: {
    async submit() {
      try {
        const res = await this.$ctforge.api.post('/challenges/submit', {
          flag: this.flag,
          name: this.name,
        });
        this.notify(res.message);
        this.updateView();
        this.$root.$emit('update-challenge');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async giveup() {
      this.$buefy.dialog.confirm({
        title: 'Give Up',
        message: 'Are you sure you want to <b>give up</b>?<br> This action cannot be undone.',
        confirmText: 'Give Up',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            const res = await this.$ctforge.api.post('/challenges/submit', {
              giveup: true,
              name: this.name,
            });
            this.notify(res.message);
            this.updateView();
            this.$root.$emit('update-challenge');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    async updateView() {
      // const loadingComponent = this.$buefy.loading.open({ container: null });
      try {
        this.data = await this.$ctforge.api.get(`/challenges/${this.name}`);
      } catch (e) {
        this.notify(e.message, 'is-danger');
        this.$router.replace('/');
      }
      // loadingComponent.close();
    },
  },
  computed: {
    markdownDescription() {
      return marked(this.data.description);
    },
  },
  async mounted() {
    await this.updateView();
    document.title = `Challenges - ${this.name}`;
  },
};
</script>

<style>
</style>
