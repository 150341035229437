import WriteupChallenge from './components/WriteupChallenge.vue';
import AdminWriteups from './components/admin/AdminWriteups.vue';
import AdminChallengeWriteupTemplate from './components/admin/AdminChallengeWriteupTemplate.vue';
import AdminEvaluation from './components/admin/AdminEvaluation.vue';

export default {

  install(vue) {
    vue.$router.addRoutes([
      {
        path: '/challenges/:challenge_name',
        component: WriteupChallenge,
        meta: { requiresLogin: true },
      },
    ]);
    // Admin interface
    vue.$router.addChildrenRoutes('/admin', [
      {
        path: 'writeups',
        component: AdminWriteups,
      },
      {
        name: 'admin_challenges_add',
        path: 'challenges/add',
        component: AdminChallengeWriteupTemplate,
        meta: { requiresAdmin: true },
        props: { isEditing: false },
      },
      {
        name: 'admin_challenges_edit',
        path: 'challenges/edit/:challenge_name',
        component: AdminChallengeWriteupTemplate,
        meta: { requiresAdmin: true },
        props: (route) => ({
          isEditing: true,
          challengeName: route.params.challenge_name,
        }),
      },
      {
        name: 'admin_evaluation',
        path: 'evaluation/:challenge_name/:user_id',
        component: AdminEvaluation,
      },
    ]);
    vue.$ctforge.addAdminLinks([
      { name: 'Writeups', path: '/admin/writeups', icon: 'file' },
    ]);
  },
};
