<template>
<div class="columns">
  <div class="column is-one-third" v-on:keyup.enter="login">
    <h1 class="pb-5 has-text-weight-bold is-size-3">Please add nickname</h1>
    <b-field label="Nickname" label-position="on-border">
      <b-input ref="nickname" type="text" v-model="nickname" placeholder="nickname" expanded required focus ></b-input>
    </b-field>

    <b-field>
      <b-button type="is-primary" @click="login"> Add Nickname </b-button>
    </b-field>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      nickname: '',
    };
  },
  methods: {
    async login() {
      try {
        const res = await this.$ctforge.api.post('/saml/activate', {
          nickname: this.nickname,
        });
        this.notify(res.message);
        await this.$ctforge.checkLogin();
        this.$router.push('/');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
  },
};
</script>

<style>
</style>
