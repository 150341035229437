<template>
<div>

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="file" /> writeups
      </p>
    </header>

    <div class="card-content">
      <div v-if="!Object.keys(data).length">
        <p align="center"><b-icon icon="frown"/> No submissions found</p>
      </div>
      <div v-else>
        <b-tabs :multiline="true" type="is-boxed" :animated="true" :animateInitially="true" :destroy-on-hide="true">
          <template v-for="c in data">
            <b-tab-item
              :key="c.challenge_id"
              :value="c.challenge_name"
              :label="c.challenge_name">

              <nav class="level">
                <!-- Left side -->
                <div class="level-left">
                  <div class="level-item">
                    <p class="subtitle is-5">
                      <strong v-if="c.submissions">{{ c.submissions.length }}</strong> Submissions
                    </p>
                  </div>
                  <div class="level-item">
                    <b-button type="is-warning" icon-left="cog" @click="calculateScores(c.challenge_id)">
                      Calculate Scores
                    </b-button>
                  </div>
                  <div class="level-item ml-5">
                    <b-button type="is-danger" icon-left="eye" @click="publishScores(c.challenge_id, true)">
                      Publish Scores
                    </b-button>
                  </div>
                  <div class="level-item">
                    <b-button type="is-info" icon-left="eye-slash" @click="publishScores(c.challenge_id, false)">
                      Hide Scores
                    </b-button>
                  </div>

                </div>
                <div class="level-right">
                  <div class="level-item mr-5">
                    <b-button type="" icon-left="download" @click="downloadCSV(c.challenge_name)">
                      Download CSV
                    </b-button>
                  </div>
                  <div class="level-item">
                    <b-switch v-model="searchable"><b-icon icon="search"/></b-switch>
                  </div>
                </div>
              </nav>

              <b-table
                :data="c.submissions"
                hoverable
                default-sort="timestamp"
                default-sort-direction="desc"
                class="submissions"
                >
                <b-table-column label="#">
                  <span class="rownumber"></span>
                </b-table-column>
                <b-table-column field="mail" label="Mail" sortable :searchable=searchable  v-slot="props">
                  {{ props.row.mail }}
                </b-table-column>
                <b-table-column field="name" label="Name" sortable :searchable=searchable  v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="surname" label="Surname" sortable :searchable=searchable  v-slot="props">
                  {{ props.row.surname }}
                </b-table-column>
                <b-table-column field="nickname" label="Nick" sortable :searchable=searchable  v-slot="props">
                  {{ props.row.nickname }}
                </b-table-column>
                <b-table-column field="history" label="History" v-slot="props">
                  {{ props.row.history.length + 1 }}
                </b-table-column>
                <b-table-column field="timestamp" label="Timestamp" sortable v-slot="props">
                  {{ props.row.timestamp }}
                </b-table-column>
                <b-table-column field="flag" label="Flag" centered v-slot="props">
                  <b-icon type="is-success" icon="check-circle" v-if="props.row.correct"/>
                  <b-icon type="is-danger" icon="times-circle" v-if="props.row.givenup"/>
                </b-table-column>
                <b-table-column field="score" label="Score" centered width="120" v-slot="props">
                  <b-icon icon="eye-slash" v-if="!props.row.visible" />
                  <b-icon icon="eye" type="is-danger" v-else />
                  <span class="pr-2"/>
                  <b-tag :class="props.row.partial? 'is-warning':'is-info'" v-if="props.row.score">{{ props.row.score.toFixed(2) }}</b-tag>
                  <b-icon icon="minus" v-if="!props.row.score"/>
                  <b-icon icon="exclamation" type="is-warning" v-if="props.row.partial" />
                </b-table-column>
                <b-table-column label="Actions" width="40" centered v-slot="props">
                  <router-link :to="{ name: 'admin_evaluation', params: { challenge_name: c.challenge_name, user_id: props.row.user_id } }">
                    <b-icon :icon="props.row.score? 'edit':'list'"/>
                  </router-link>
                </b-table-column>
              </b-table>
            </b-tab-item>
          </template>
        </b-tabs>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      searchable: false,
    };
  },
  methods: {
    async calculateScores(id) {
      const loading = this.$buefy.loading.open({ container: null });
      try {
        await this.$ctforge.api.post('/admin/writeup/calculate-scores', { challenge_id: id });
      } catch (e) {
        this.notify(e.message, 'is-danger');
      } finally {
        loading.close();
        await this.update();
      }
    },
    async publishScores(id, visible) {
      const loading = this.$buefy.loading.open({ container: null });
      try {
        await this.$ctforge.api.post(`/admin/writeup/publish-scores?visible=${visible}`, { challenge_id: id });
      } catch (e) {
        this.notify(e.message, 'is-danger');
      } finally {
        loading.close();
        await this.update();
      }
    },
    async update() {
      const loadingComponent = this.$buefy.loading.open({ container: null });
      try {
        this.data = await this.$ctforge.api.get('/admin/writeup/list');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      loadingComponent.close();
    },
    downloadCSV(challengeName) {
      const { submissions } = this.data[challengeName];
      let csv = 'Mail,Name,Surname,MN,Flag,Score\n';
      submissions.forEach((s) => {
        if (s.mn) {
          csv += `${s.mail},${s.name},${s.surname},${s.mn},${+(s.correct && !s.givenup)},${s.score.toFixed(2)}\n`;
        }
      });
      const blob = new Blob([csv], { type: 'text/csv', charset: 'UTF-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${challengeName}.csv`;
      link.click();
    },
  },
  async mounted() {
    await this.update();
  },
};
</script>

<style>
.submissions table {
  counter-reset: rowNumber;
}
.submissions table span.rownumber::before {
  display: table-cell;
  counter-increment: rowNumber;
  content: counter(rowNumber);
  padding-right: 0.3em;
  text-align: right;
}
</style>
