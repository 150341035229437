import VueRouter from 'vue-router';

export default {
  // eslint-disable-next-line camelcase
  createRouter(initial_routes) {
    const make = () => new VueRouter({ routes: initial_routes });
    const router = make();
    // eslint-disable-next-line camelcase
    router.addRoutes = (new_routes) => {
      new_routes.forEach((route) => {
        initial_routes
          .map((ir, ii) => ((ir.path === route.path) ? ii : null))
          .forEach((idx) => {
            if (idx) initial_routes.splice(idx, 1);
          });
        initial_routes.push(route);
      });
      router.matcher = make().matcher;
      if (router.currentRoute) {
        router.replace(router.currentRoute.path);
      }
    };
    // eslint-disable-next-line camelcase
    router.addChildrenRoutes = (path, new_children) => {
      const handler = initial_routes.filter((r) => r.path === path)[0];
      // eslint-disable-next-line no-console
      console.assert(handler);
      handler.children = handler.children.filter((rt) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < new_children.length; ++i) {
          // eslint-disable-next-line eqeqeq
          if (new_children[i].path == rt.path) return false;
        }
        return true;
      });
      handler.children = handler.children.concat(new_children);
      router.addRoutes([handler]);
    };
    return router;
  },
};
