<template>
<div>

  <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="chart-bar" />
          Summary
        </p>
      </header>
      <div class="card-content">
        <div class="content">

          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Users</p>
                <p class="title">{{ users }}</p>
              </div>
            </div>
            <!-- TODO: make slots or hooks into ctforge object -->
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Challenges</p>
                <p class="title is-size-5">¯\_(ツ)_/¯</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Solvers</p>
                <p class="title is-size-5">¯\_(ツ)_/¯</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Submitted Writeups</p>
                <p class="title is-size-5">¯\_(ツ)_/¯</p>
              </div>
            </div>
          </nav>

        </div>
      </div>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      users: 0,
    };
  },
  async mounted() {
    try {
      const users = await this.$ctforge.api.get('/admin/users/list');
      this.users = users.length;
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>

<style>
</style>
