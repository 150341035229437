<template>
<div class="card">
  <header class="card-header">
    <p class="card-header-title">
      <span v-if="isEditing">
        <b-icon icon="edit" />
        Edit Role
      </span>
      <span v-else>
        <b-icon icon="plus" />
        Add Role
      </span>
    </p>
    <span class="card-header-icon" aria-label="back">
      <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
    </span>
  </header>
  <div class="card-content">
    <div class="content">

      <form @submit.prevent="submit">
        <b-field label="Name">
          <b-input type="text" required v-model="role.name"></b-input>
        </b-field>

        <b-field label="Permissions">
          <multiselect
            v-model="role.permissions"
            :options="allPermissions"
            :multiple="true"
            placeholder="Permissions for this role"></multiselect>
        </b-field>

        <b-field label="Users">
          <multiselect
            v-model="role.users"
            :options="allUsers"
            :multiple="true"
            :custom-label="userLabel"
            placeholder="Users with this role"
            label="mail"
            track-by="mail"></multiselect>
        </b-field>

        <slot name="extras"></slot>

        <b-field grouped>
          <b-button native-type="submit" class="is-primary">Save</b-button>
          <b-button class="ml-2" @click="cancel">Cancel</b-button>
        </b-field>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      id: null,
      role: {
        name: null,
        permissions: null,
        users: null,
      },
      allPermissions: [],
      allUsers: [],
    };
  },
  methods: {
    async submit() {
      // browser takes care of validation using required fields (works because we're using onSubmit)
      try {
        if (this.isEditing) {
          await this.$ctforge.api.post('/admin/acs/roles/edit', {
            id: this.id,
            ...this.role,
          });
        } else {
          await this.$ctforge.api.post('/admin/acs/roles/add', this.role);
        }
        this.$router.push('/admin/roles/');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    cancel() {
      this.$router.push('/admin/roles/');
    },
    async loadAllPermissions() {
      try {
        const resp = await this.$ctforge.api.get('/admin/acs/policy/get');
        this.allPermissions = resp.permissions;
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async loadUsers() {
      try {
        this.allUsers = await this.$ctforge.api.get('/admin/users/list');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    userLabel(user) {
      return `${user.name} ${user.surname} (${user.mail})`;
    },
  },
  async mounted() {
    await this.loadAllPermissions();
    await this.loadUsers();
    if (!this.isEditing) {
      return;
    }

    try {
      const { id } = this.$route.params;
      const resp = await this.$ctforge.api.get(`/admin/acs/roles/get/${id}`);
      // Manually copy because response contains extra fields
      this.role = {
        name: resp.name,
        permissions: resp.permissions,
        users: resp.users,
      };
      this.id = resp.id;
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>
