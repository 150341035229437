<template>
<div>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="code" /> Edit Page
      </p>
      <span class="card-header-icon" aria-label="back">
          <b-button type="is-small" icon-left="arrow-left" @click="$router.back()">Back</b-button>
      </span>

    </header>

    <div class="card-content">

      <b-loading v-model="loading" :is-full-page="false" />
      <b-tabs>
        <b-tab-item label="Editor" icon="edit">
          <form @submit.prevent="submit">

            <b-field label="Route">
              <b-input v-model="route" placeholder="Page Route (e.g. /page/1)" required></b-input>
            </b-field>

            <b-field label="Title">
              <b-input v-model="title" placeholder="Page Title" required></b-input>
            </b-field>

            <b-field label="Icon">
              <b-input v-model="icon" placeholder="Navbar Icon (without `fa-' prefix, e.g. icons)"></b-input>
            </b-field>

            <b-field label="Order">
              <b-input v-model="order" placeholder="Navbar order (int, <0 hide from navigation bar, default -1)"></b-input>
            </b-field>

            <b-field label="Options" class="mb-5">
              <b-checkbox v-model="login_required" class="pr-5">Requires Login</b-checkbox>
              <b-checkbox v-model="hidden">Hidden</b-checkbox>
            </b-field>

            <b-field>
              <MonacoEditor class="editor" v-model="content" :language="markdown? 'markdown':'html'" theme="vs-dark" :options="monaco_options" />
            </b-field>

            <b-field>
              <b-switch v-model="markdown" class="mr-5 pr-5">Markdown</b-switch>
              <b-button native-type="submit" class="is-primary" expanded>Save</b-button>
            </b-field>
          </form>
        </b-tab-item>

        <b-tab-item label="Preview" icon="eye">
          <div class="content" v-html="content" v-if="!markdown"></div>
          <div class="content" v-else v-html="markdownPage()"></div>
        </b-tab-item>
  </b-tabs>

  </div>
    </div>
    </div>
</template>

<script>
import MonacoEditor from 'vue-monaco';
import marked from 'marked';

export default {
  props: ['pageRoute'],
  components: {
    MonacoEditor,
  },
  data() {
    return {
      title: null,
      route: null,
      icon: null,
      content: '',
      markdown: false,
      hidden: false,
      login_required: false,
      order: null,
      monaco_options: {
        tabSize: 4,
        lineNumbers: true,
        lineNumbersMinChars: 3,
        folding: false,
        minimap: { enabled: true },
        automaticLayout: true,
        wordWrap: true,
      },
      loading: false,
    };
  },
  methods: {
    async submit() {
      try {
        const params = {};
        ['title', 'route', 'icon', 'content', 'markdown', 'hidden', 'login_required', 'order'].forEach((name) => {
          params[name] = this[name];
        });
        if (!params.order) params.order = null;
        await this.$ctforge.api.post('/admin/cms/page/edit', params);
        this.notify('Page Updated!');
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      this.$router.back();
    },
    markdownPage() { return marked(this.content); },
  },
  async mounted() {
    if (!this.pageRoute) return;
    this.loading = true;
    try {
      const resp = await this.$ctforge.api.get(`/cms/page/${this.pageRoute}`);
      ['title', 'route', 'icon', 'content', 'markdown', 'hidden', 'login_required', 'order'].forEach((name) => {
        this[name] = resp[name];
      });
    } catch (e) {
      if (e.status_code !== 404) this.notify(e.message, 'is-danger');
    }
    this.loading = false;
  },
};
</script>
<style>
</style>
