<template>
<ChallengeInfo>
  <template v-slot:writeups>
    <WriteupForm/>
  </template>
</ChallengeInfo>
</template>

<script>
import ChallengeInfo from '../../challenges/components/ChallengeInfo.vue';
import WriteupForm from './WriteupForm.vue';

export default {
  components: {
    ChallengeInfo,
    WriteupForm,
  },
};
</script>

<style>
</style>
