import UserProfileWithKeys from './components/UserProfileWithKeys.vue';

export default {

  install(vue) {
    const userRecord = vue.$router.options.routes.filter((e) => e.path === '/user')[0];
    const userComponent = userRecord.component;
    const userComponentProps = userRecord.props;

    vue.$router.addRoutes([
      {
        path: '/user',
        component: UserProfileWithKeys,
        props: { baseComponent: userComponent, baseComponentProps: userComponentProps },
        meta: { requiresLogin: true },
      },
    ]);
  },

};
