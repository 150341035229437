<template>
<div>
  <b-navbar type="is-light" style="z-index: 1">
    <template slot="brand">
      <b-navbar-item tag="router-link" to="/admin">
        <b-icon class="pr-3 pr-5 ml-4" icon="cogs"/> <b>Admin Panel</b>
      </b-navbar-item>
      <span style="border-right: 1px solid lightgray;"/>
    </template>
    <template slot="start">
      <b-navbar-item class='ml-1' tag="router-link" :to="item.path"
                     v-for="item in links" :key="item.name">
        <b-icon class="pr-3" :icon="item.icon" v-if="item.icon"/> {{ item.name }}
      </b-navbar-item>

    </template>

    <template slot="end">
      <b-navbar-item tag="router-link" to="/admin/config">
        <b-icon class="pr-3" icon="cog"/> Configuration
      </b-navbar-item>
    </template>
  </b-navbar>
  <div class="container mt-5">
    <router-view />
  </div>
</div>
</template>

<script>
export default {
  computed: {
    links() {
      return this.$ctforge.state.adminbar;
    },
  },
  mounted() {
    document.title = 'Admin Panel';
  },
};
</script>

<style>
  .navbar-brand .navbar-item {
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .navbar-end .navbar-item {
    border-top-right-radius: 0.4em;
    border-bottom-right-radius: 0.4em;
  }
</style>
