<template>
<div v-if="template">
  <div class="card mt-5" v-if="!!evaluation">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="star" />
        Evaluation
      </p>
    </header>
    <div class="card-content">
      <div class="field is-floating-label">
        <label class="label">Score</label>
        <div class="box">
          <p>{{ evaluation.score.toFixed(2) }}</p>
        </div>
      </div>
      <div class="field is-floating-label mt-5" v-if="!!evaluation.text && !isEditingEvaluation">
        <label class="label">Feedback</label>
        <div class="box">
          <pre class="evaluation">{{ evaluation.text }}</pre>
        </div>
      </div>
      <div class="box has-background-info-dark"  v-if="isEditingEvaluation">
        <b-field label="Feedback" label-position="on-border">
          <b-input type="textarea" rows="10" placeholder="Feedback" v-model="evaluation.text"></b-input>
        </b-field>
        <b-field>
          <b-button expanded native-type="submit" class="is-warning" icon-left="save" @click="saveEvaluation">
            Save Evaluation &amp; Recalculate Score
          </b-button>
        </b-field>
      </div>
    </div>
  </div>

  <div class="card mt-5">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="paper-plane" />
        Recap Questions
      </p>
    </header>
    <div class="card-content content">
      <div class="box has-background-primary has-text-primary-light has-text-centered" v-if="writeup.timestamp">
        <b-icon icon="check-circle" /> Answers Submitted on {{ new Date(writeup.timestamp) }}
      </div>

      <span v-html="marked(template.description)"></span>

      <form @submit.prevent="askSubmit" class="mt-5">

        <b-field v-for="q, q_idx in writeup.answers" v-bind:key="q.description">
          <div class="box">
            <div class="mb-3"><p><b>{{ q_idx+1 }}.</b> <span v-html="marked(q.question.description)"></span></p></div>
            <b-field v-if="q.type === 'input'">
              <b-input expanded :disabled="isDisabled" placeholder="Your answer" v-model="q.input"/>
            </b-field>
            <div v-if="q.type === 'input' && !!evaluation" class="box has-background-primary has-text-primary-light mt-3">
              Correct answers: <code style="background:inherit; color:inherit; white-space: pre-wrap; word-break: break-all">{{ getScoreEntry(q.question.id).answer.question.correct }}</code>
            </div>
            <b-field v-if="q.type === 'text'">
              <b-input type="textarea" :disabled="isDisabled" rows="10" placeholder="Your answer" v-model="q.text"></b-input>
            </b-field>
            <b-field v-if="q.type === 'mc'">
              <table width="100%">
                <tr v-for="c in q.choices" v-bind:key="c.description">
                  <td width="65%" class="pb-3">
                    <p><span v-html="marked(c.choice.description)"></span> </p>
                  </td>
                  <td class="choice">
                    <b-radio :disabled="isDisabled" :name="q.question.id+'_'+c.choice.id" native-value="true"
                             :class="getChoice(q.question.id, c.choice.id).true_multiplier > 0 ? 'correct' : ''"
                             v-model="c.answer"><b>true</b></b-radio>
                  </td>
                  <td class="choice">
                    <b-radio :disabled="isDisabled" :name="q.question.id+'_'+c.choice.id" native-value="false"
                             :class="getChoice(q.question.id, c.choice.id).false_multiplier > 0 ? 'correct' : ''"
                             v-model="c.answer"><b>false</b></b-radio>
                  </td>
                  <td class="choice">
                    <b-button v-if="!isDisabled" icon-left="eraser" title="delete answer" @click="c.answer=null"></b-button>
                  </td>
                </tr>
              </table>
            </b-field>
            <nav class="mt-3" v-if="!!evaluation">
              <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="level-item">
                  <b-tag class="is-warning">Score: {{ getScore(q.question.id).toFixed(2) }}</b-tag>
                </div>
              </div>
              </div>
              <div class="box has-background-info-dark" v-if="isEditingEvaluation">
              <b-field grouped  position="is-centered" >
                <b-field label="Score">
                  <b-input disabled v-model="getScoreEntry(q.question.id).score"/>
                </b-field>
                <b-field label="Override" class="ml-3">
                  <b-numberinput step="0.1" v-model="getScoreEntry(q.question.id).override"/>
                </b-field>
                <b-field label="Delete Override" class="ml-3">
                  <b-button icon-left="trash" class="is-warning" title="delete override" @click="getScoreEntry(q.question.id).override=null"></b-button>
                </b-field>
              </b-field>
              </div>
            </nav>
          </div>
        </b-field>
        <b-field class="mt-5">
          <b-button expanded native-type="submit" class="is-primary"
                    :icon-left="(writeup.timestamp && template.editable) ? 'edit' : 'save'" v-if="!isDisabled">
            <span v-if="writeup.timestamp && template.editable">Edit Submission</span>
            <span v-else>Save &amp; Submit</span>
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import marked from 'marked';

export default {
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
    },
    edit_evaluation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      name: this.$route.params.challenge_name,
      template: null,
      writeup: {
        timestamp: null,
        answers: [],
      },
      evaluation: null,
    };
  },
  methods: {
    marked(text) {
      return marked.parseInline(text);
    },
    async askSubmit() {
      if (this.template.editable) this.submit();
      else {
        this.$buefy.dialog.confirm({
          title: 'Submit Answers',
          message: 'Once submitted, your answers cannot be changed.',
          confirmText: 'Submit',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: this.submit,
        });
      }
    },
    async submit() {
      try {
        await this.$ctforge.api.post(`/challenges/${this.name}/writeup/submit`, this.writeup);
        this.notify('Answers Submitted!');
        this.updateView();
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async saveEvaluation() {
      try {
        await this.$ctforge.api.post(`/admin/writeup/evaluation/${this.name}/${this.user_id}`, this.evaluation);
        this.notify('Evaluation Updated.');
        this.updateView();
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async updateView() {
      const loadingComponent = this.$buefy.loading.open({ container: null });
      try {
        this.template = await this.$ctforge.api.get(`/challenges/${this.name}/writeup/template`);
        this.writeup.template_id = this.template.challenge_id;
        this.template.questions.forEach((q, q_idx) => { // eslint-disable-line camelcase
          this.$set(this.writeup.answers, q_idx, {
            type: q.type,
            question: q,
          });
          if (q.type === 'mc') {
            this.$set(this.writeup.answers[q_idx], 'choices', []);
            q.choices.forEach((c, c_idx) => { // eslint-disable-line camelcase
              this.$set(this.writeup.answers[q_idx].choices, c_idx, {
                choice: c,
                answer: null,
              });
            });
          }
        });
        const answers = await this.$ctforge.api.get(`/challenges/${this.name}/writeup/answers${this.user_param}`);
        this.writeup = answers;
        this.template.editable = (new Date() >= new Date(this.template.submission_ending_time)) ? false : this.template.editable;
        const evaluation = await this.$ctforge.api.get(`/challenges/${this.name}/writeup/scores${this.user_param}`);
        this.evaluation = evaluation;
      } catch (e) {
        if (e.status_code !== 400) this.notify(e.message, 'is-danger');
      }
      loadingComponent.close();
    },
    getScoreEntry(questionId) {
      if (this.evaluation && this.evaluation.scores) {
        const answerScore = this.evaluation.scores.find((s) => s.answer.question.id === questionId);
        if (!answerScore) return null;
        return answerScore;
      }
      return null;
    },
    getScore(questionId) {
      const answerScore = this.getScoreEntry(questionId);
      if (!answerScore) return 0;
      const score = (answerScore.score) ? answerScore.score : 0;
      return (answerScore.override !== null) ? answerScore.override : score;
    },
    getChoice(questionId, choiceId) {
      const se = this.getScoreEntry(questionId);
      if (!se) return {};
      return se.answer.question.choices.find((s) => s.id === choiceId);
    },
  },
  computed: {
    isDisabled() {
      return (!!this.user_id) || (!this.template.editable && !!this.writeup.timestamp);
    },
    user_param() {
      if (this.user_id) {
        return `?user=${this.user_id}`;
      }
      return '';
    },
    isEditingEvaluation() {
      return !!this.evaluation && !!this.user_id && this.edit_evaluation;
    },
  },
  async mounted() {
    this.updateView();
    this.$root.$on('update-challenge', this.updateView);
  },
  beforeDestroy() {
    this.$root.$off('update-challenge', this.updateView);
  },
};
</script>

<style>
  td.choice {
    text-align: center !important;
    white-space:nowrap;
    max-width: 1em;
    padding: 0.2ex;
  }
  td.choice label {
      padding: 0.2ex;
  }
  td.choice label.correct {
      border: 3px solid hsl(171,100%,41%);
      border-radius: 1ex;
  }
  pre.evaluation {
      background: inherit;
      white-space: pre-wrap;
  }
</style>
