<template>
<div>
  <div class="card mb-5">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="columns" /> Pages
      </p>
    </header>

    <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

    <div class="card-content" v-else>
      <div class="content">

         <nav class="level">
          <div class="level-right">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ pages.length }}</strong> Pages
              </p>
            </div>
          </div>
          <div class="level-right">
             <div class="level-item">
              <b-switch v-model="searchablePages"><b-icon icon="search"/></b-switch>
             </div>
             <p class="level-item">
               <b-button icon-left="plus" tag="router-link"
                         to="/admin/pages/edit">
                 Add Page
               </b-button>
             </p>
           </div>
        </nav>

        <b-table
          :data="pages"
          hoverable
          default-sort="route"
          default-sort-direction="desc">
          <b-table-column field="title" label="Title" sortable :searchable="searchablePages"  v-slot="props">
            {{ props.row.title }}
          </b-table-column>
          <b-table-column field="route" label="Route" sortable :searchable="searchablePages" v-slot="props">
            <span class="tag is-success is-family-monospace" v-if="props.row.route == '__index__'">/</span>
            <span class="tag is-success is-family-monospace" v-else-if="props.row.route == '__header__'">&lt;NavBar Header&gt;</span>
            <span class="tag is-info is-family-monospace" v-else>/{{ props.row.route }}</span>
          </b-table-column>
          <b-table-column field="order" label="Order" sortable width="40" centered v-slot="props">
            <b-icon icon="times" v-if="props.row.order === null" />
            <span v-else>
              {{ props.row.order }}
            </span>
          </b-table-column>

          <b-table-column field="hidden" label="Hidden" width="40" centered v-slot="props">
            <b-icon icon="check" v-if="props.row.hidden"/>
            <b-icon icon="times" v-else />
          </b-table-column>
          <b-table-column field="login_required" label="Req. Login" width="110" centered  v-slot="props">
            <b-icon icon="check" v-if="props.row.login_required"/>
            <b-icon icon="times" v-else />
          </b-table-column>
          <b-table-column label="Actions" width="120" centered v-slot="props">
             <router-link class="pr-3" :to="'/' + (props.row.route == '__index__' ? '' : props.row.route)">
              <b-icon icon="eye" />
             </router-link>
             <router-link  class="pr-3" :to="'/admin/pages/edit?p='+encodeURIComponent(props.row.route)">
              <b-icon icon="edit"/>
             </router-link>
             <a @click="deletePage(props.row.route)">
              <b-icon icon="trash"/>
             </a>
          </b-table-column>
        </b-table>

      </div>
    </div>
  </div>

    <div class="card mb-5">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="folder-open" /> Files
      </p>
    </header>

    <div class="card-content" v-if="unauthorized">
        <div class="content">
          <p class="has-text-centered has-text-weight-bold is-size-4">
             <b-icon icon="exclamation-triangle" /> <br/>
             Unauthorized!
          </p>
        </div>
      </div>

    <div class="card-content" v-else>
      <div class="content">

         <nav class="level">
          <div class="level-right">
            <div class="level-item">
              <p class="subtitle is-5">
                <strong>{{ files.length }}</strong> Files
              </p>
            </div>
          </div>
          <div class="level-right">
             <div class="level-item">
              <b-switch v-model="searchableFiles"><b-icon icon="search"/></b-switch>
             </div>
             <p class="level-item">
               <b-button icon-left="plus" @click="addFile">
                 Add File
               </b-button>
             </p>
           </div>
        </nav>

        <b-table
          :data="files"
          hoverable>
          <b-table-column field="name" label="Name" sortable :searchable=searchableFiles  v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="hash" label="Hash" sortable :searchable=searchableFiles  v-slot="props">
            {{ props.row.hash }}
          </b-table-column>
          <b-table-column field="public" label="Public" centered sortable width="40" v-slot="props">
            <b-icon icon="check" v-if="props.row.public"/>
            <b-icon icon="times" v-else />
          </b-table-column>
          <b-table-column label="Actions" width="140" centered v-slot="props">
             <a class="pr-3" :href="`/cms/files/${props.row.hash}/${props.row.name}`" target="_blank">
              <b-icon icon="link" title="Link" />
             </a>
             <a class="pr-3" @click="editUsers(props.row.hash, props.row.name)">
              <b-icon icon="users" title="Users" />
             </a>
             <a @click="deleteFile(props.row.hash)">
              <b-icon icon="trash" title="Delete" />
             </a>
          </b-table-column>
        </b-table>

      </div>
    </div>
  </div>

</div>
</template>

<script>
import Multiselect from 'vue-multiselect';

const AddFileForm = {
  template: `
   <form @submit.prevent="upload">
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="file" />
                 Add File
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body">

               <b-progress v-if="loading" />

               <b-upload v-model="files" multiple drag-drop expanded>
                     <section class="section">
                       <div class="content has-text-centered">
                         <p>
                           <b-icon icon="upload" size="is-large"></b-icon>
                         </p>
                         <p>Drop your files here or click to upload</p>
                       </div>
                     </section>
                   </b-upload>
                 </b-field>
                 <div class="tags mt-2">
                     <span v-for="(file, index) in files"
                         :key="index"
                         class="tag is-primary">
                         {{file.name}}
                     </span>
                 </div>

           </section>
           <footer class="modal-card-foot">
               <b-button
                   label="Close"
                   expanded
                   @click="$emit('close')" />
               <b-button
                   label="Upload"
                   native-type="submit"
                   expanded
                   type="is-primary" />
           </footer>
       </div>
   </form>
`,
  data() {
    return {
      files: [],
      loading: false,
    };
  },
  methods: {
    async upload() {
      this.loading = true;
      try {
        await Promise.all(this.files.map(async (file) => {
          await this.uploadFile(file);
        }));
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      this.loading = false;
      this.$emit('close');
    },
    async uploadFile(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const res = await fetch('/admin/cms/files/upload', {
          method: 'POST',
          headers: { // TODO: move into this.$ctforge
            'X-SEC-CTFORGE': 'csrf-protection',
          },
          body: formData,
        });
        const js = await res.json();
        if (res.status >= 200 && res.status <= 299) return js;
        throw new Error(js.message);
      } catch (e) {
        this.notify(e.message, 'is-danger');
        throw e;
      }
    },
  },
};

const UsersFileForm = {
  components: { Multiselect },
  props: ['name', 'hash'],
  template: `
   <form @submit.prevent="update">
       <div class="modal-card" style="width: 60em">
           <header class="modal-card-head">
               <p class="modal-card-title">
                 <b-icon icon="file" />
                 Update File Users
               </p>
               <button
                   type="button"
                   class="delete"
                   @click="$emit('close')"/>
           </header>
           <section class="modal-card-body" style="height: 20em">
               <b-progress v-if="loading" />
               <b-field label="Users">
                 <multiselect
                     v-model="users"
                     :options="all_users"
                     :multiple="true"
                     :custom-label="userLabel"
                     placeholder="Users that can download this file"
                     label="mail"
                     track-by="mail"></multiselect>
               </b-field>

           </section>
           <footer class="modal-card-foot">
               <b-button
                   label="Close"
                   expanded
                   @click="$emit('close')" />
               <b-button
                   label="Save"
                   native-type="submit"
                   expanded
                   type="is-primary" />
           </footer>
       </div>
   </form>
`,
  data() {
    return {
      users: [],
      all_users: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      this.all_users = await this.$ctforge.api.get('/admin/users/list');
      this.users = await this.$ctforge.api.get(`/admin/files/${this.hash}/users/get`);
    } catch (e) {
      this.notify(e.message, 'is-danger');
    }
    this.loading = false;
  },
  methods: {
    async update() {
      this.loading = true;
      try {
        await this.$ctforge.api.post(`/admin/files/${this.hash}/users/set`, this.users.map((u) => ({ mail: u.mail })));
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
      this.loading = false;
      this.$emit('close');
    },
    userLabel(user) {
      return `${user.name} ${user.surname} (${user.mail})`;
    },
  },
};

export default {
  data() {
    return {
      pages: [],
      files: [],
      searchablePages: false,
      searchableFiles: false,
      unauthorized: false,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        this.pages = await this.$ctforge.api.get('/cms/pages');
        this.files = await this.$ctforge.api.get('/admin/cms/files');
      } catch (e) {
        if (e.status_code === 401) {
          this.unauthorized = true;
        } else this.notify(e.message, 'is-danger');
      }
    },
    deletePage(route) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Page',
        message: `Are you sure you want to <b>delete</b> page '${route}'? This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$ctforge.api.post('/admin/cms/page/delete', {
              route,
            });
            await this.refresh();
            this.notify('Page Deleted', 'is-info');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    addFile() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: AddFileForm,
        hasModalCard: true,
        trapFocus: false,
      });
      modal.$on('close', () => {
        this.refresh();
      });
    },
    deleteFile(hash) {
      this.$buefy.dialog.confirm({
        title: 'Deleting File',
        message: 'Are you sure you want to <b>delete</b> the file? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$ctforge.api.post(`/admin/cms/files/${hash}/delete`);
            await this.refresh();
            this.notify('File Deleted', 'is-info');
          } catch (e) {
            this.notify(e.message, 'is-danger');
          }
        },
      });
    },
    editUsers(hash, name) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: UsersFileForm,
        hasModalCard: true,
        trapFocus: false,
        props: { hash, name },
      });
      modal.$on('close', () => {
        this.refresh();
      });
    },
  },
};
</script>

<style>
  .editor {
    width: 100%;
    height: 600px;
    border: 4px solid #003f50;
    border-radius: 4px;
  }
</style>
