<template>
<AdminChallengeForm :isEditing="isEditing" :challengeName="challengeName" @submitCallback="submit">
  <template v-slot:extras>
    <hr/>
    <label class="label pb-2">Writeup Template</label>
    <b-field  v-if="(!template) || template.delete">
      <b-button icon-left="plus-circle" class="is-primary" @click="addTemplate">Add Writeup Template</b-button>
      <b-button icon-left="code" class="ml-3" @click="addJSONTemplate">Import JSON Template</b-button>
    </b-field>
    <div v-else>
      <b-field label="Assignment Description" label-position="on-border">
        <b-input required v-model="template.description" type="textarea" rows="5" placeholder="Description" />
      </b-field>
      <b-field grouped position="is-centered">
        <b-field label="Flag Multiplier">
          <b-input required type="number" step="any" v-model="template.flag_multiplier" placeholder="1.0"></b-input>
        </b-field>
        <b-field label="Giveup Multiplier">
          <b-input required type="number"  step="any" v-model="template.giveup_multiplier" placeholder="1.0"></b-input>
        </b-field>
        <b-field label="Editable">
          <b-switch class="pt-2" v-model="template.editable">{{ template.editable }}</b-switch>
        </b-field>
      </b-field>
      <b-field grouped position="is-centered" class="pt-1">
        <b-field label="Submission Start Time"  label-position="on-border">
          <b-datetimepicker
            v-model="template.submission_starting_time"
            placeholder="Click to select..."
            icon="calendar"
            horizontal-time-picker />
        </b-field>
        <b-field label="Submission Ending Time" label-position="on-border">
          <b-datetimepicker
            v-model="template.submission_ending_time"
            placeholder="Click to select..."
            icon="calendar"
            horizontal-time-picker />
        </b-field>
      </b-field>
      <div class="mt-3" v-for="q, idx in template.questions" v-bind:key="idx">
        <b-field grouped :label="`Question ${idx + 1}`" label-position="inside" position="is-right">
          <span class="button mr-2 is-static is-rounded">{{ q.type }}</span>
          <b-button icon-left="arrow-up" class="is-info mr-2" @click="move(template.questions, idx, idx-1)"></b-button>
          <b-button icon-left="arrow-down" class="is-info mr-2" @click="move(template.questions, idx, idx+1)"></b-button>
          <b-button icon-left="trash" class="is-danger" @click="template.questions.splice(idx, 1)"></b-button>
        </b-field>
        <b-field label="Question Description" label-position="on-border">
          <b-input required v-model="q.description" type="textarea" rows="5" placeholder="Description" />
        </b-field>
        <div v-if="q.type === 'input'">
          <b-field grouped group-multiline position="is-centered" class="pb-4">
            <b-field label="Correct Score">
              <b-input required type="number" step="any" v-model="q.correct_score" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="Wrong Score">
              <b-input required type="number" step="any" v-model="q.wrong_score" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="Empty Score">
              <b-input required type="number" step="any" v-model="q.empty_score" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="Correct Answers">
              <b-taginput
                ellipsis
                v-model="q.correct"
                type="is-info"
                icon="angle-right"
                placeholder="Add Correct Answer"
                aria-close-label="Delete" />
            </b-field>
          </b-field>
        </div>
        <div v-if="q.type === 'mc'">
           <b-field grouped group-multiline position="is-centered" class="pb-5">
            <b-field label="Base Score">
              <b-input required type="number" step="any" v-model="q.base_score" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="Filter Function">
              <b-select v-model="q.mod" required placeholder="Question Type">
                <option value="ID">ID</option>
                <option value="MAX0">MAX0</option>
              </b-select>
            </b-field>
          </b-field>
          <div v-for="c, cidx in q.choices" v-bind:key="cidx" class="mt-2">
          <b-field grouped>
            <b-input required expanded v-model="c.description" type="textarea" rows="2" placeholder="Description" />
             <b-field label="True Multiplier">
              <b-input required type="number" step="any" v-model="c.true_multiplier" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="False Multiplier">
              <b-input required type="number" step="any" v-model="c.false_multiplier" placeholder="1.0"></b-input>
            </b-field>
            <b-field label="Empty Multiplier">
              <b-input required type="number" step="any" v-model="c.empty_multiplier" placeholder="1.0"></b-input>
            </b-field>
            <b-field class="is-flex is-flex-direction-column is-justify-content-end pb-1">
              <b-button icon-left="trash" class="is-danger" @click="q.choices.splice(cidx, 1)"></b-button>
            </b-field>
          </b-field>
          </div>
          <b-field class="mt-5" position="is-left">
            <b-button icon-left="plus-circle" class="is-primary" @click="addChoice(idx)">Add Choice</b-button>
          </b-field>
        </div>
      </div>
      <b-field class="mt-5" position="is-left">
        <b-select v-model="type" required placeholder="Question Type">
                <option value="text">Text Area</option>
                <option value="input">Input</option>
                <option value="mc">Multiple Choice</option>
        </b-select>
        <p class="control">
          <b-button icon-left="plus-circle" class="is-primary" @click="addQuestion">Add Question</b-button>
        </p>
      </b-field>
      <b-field>
        <b-button icon-left="trash" class="is-danger" @click="deleteTemplate">Delete Writeup Template</b-button>
        <b-button icon-left="file-export" class="ml-3" @click="exportTemplate">Export JSON</b-button>
      </b-field>

    </div>
    <hr/>
  </template>
</AdminChallengeForm>
</template>

<script>
import AdminChallengeForm from '../../../challenges/components/admin/AdminChallengeForm.vue';

export default {
  props: {
    isEditing: Boolean,
    challengeName: {
      required: false,
    },
  },
  data() {
    return {
      template: null,
      type: 'text',
    };
  },
  components: {
    AdminChallengeForm,
  },
  methods: {
    addTemplate() {
      this.template = {
        challenge_id: null,
        description: '',
        flag_multiplier: null,
        giveup_multiplier: null,
        editable: true,
        submission_starting_time: null,
        submission_ending_time: null,
        questions: [],
      };
    },
    addJSONTemplate() {
      this.$buefy.dialog.prompt({
        message: 'Import Writeup Template',
        inputAttrs: {
          placeholder: 'JSON',
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.addTemplate();
          this.template = JSON.parse(value);
        },
      });
    },
    addQuestion() {
      if (!this.type) return;
      this.template.questions.push({
        description: '',
        type: this.type,
      });
    },
    addChoice(idx) {
      if (!this.template.questions[idx].choices) {
        this.$set(this.template.questions[idx], 'choices', []);
      }
      this.template.questions[idx].choices.push({
        description: '',
      });
    },
    move(array, oldIdx, newIdx) {
      if (newIdx >= 0 && newIdx < array.length) {
        array.splice(newIdx, 0, array.splice(oldIdx, 1)[0]);
      }
    },
    async submit(id) {
      if (!this.template) return;
      try {
        this.template.challenge_id = id;
        this.$ctforge.api.post('/admin/writeup/template', this.template);
      } catch (e) {
        this.notify(e.message, 'is-danger');
      }
    },
    async loadTemplate() {
      try {
        const resp = await this.$ctforge.api.get(`/challenges/${this.challengeName}/writeup/template`);
        this.template = resp;
        this.template.submission_starting_time = new Date(this.template.submission_starting_time);
        this.template.submission_ending_time = new Date(this.template.submission_ending_time);
      } catch (e) {} // eslint-disable-line no-empty
    },
    deleteTemplate() {
      this.$buefy.dialog.confirm({
        title: 'Delete Writeup Template',
        message: 'This action will delete the writeup template',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$set(this.template, 'delete', true);
        },
      });
    },
    exportTemplate() {
      const that = this;
      this.$buefy.dialog.alert({
        title: 'Export JSON',
        message: `<pre><code>${JSON.stringify(this.template).replaceAll('&', '&amp;').replaceAll('>', '&gt;').replaceAll('<', '&lt;')}</code></pre>`,
        confirmText: 'Copy',
        canCancel: true,
        onConfirm() {
          try {
            navigator.clipboard.writeText(JSON.stringify(that.template));
          } catch (e) {
            console.error(e);
          }
        },
      });
    },
  },
  async mounted() {
    if (this.isEditing) {
      await this.loadTemplate();
    }
  },
};
</script>

<style>
.field.is-floating-in-label > .label {
  top: 0.5em;
  font-size: 1em;
}
</style>
